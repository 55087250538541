<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import Dialog from "@/components/Dialog";

import {
  getTemplateList,
  deleteTemplate,
  setDefaultTemplate,
  copyTemplate,
  getListMenuNotF,
  isStopTemplate,
  getMenuIdList,
  restorePrintTemplates,
} from "@/api/system/system/printTemplate";
export default {
  name: "printTemplate",
  components: { TablePage, Dialog },
  data() {
    return {
      dialogOptions: {
        title: "",
        width: 550,
        show: false,
        type: "PrintTemplate",
        formData: null,
      },
      options: {
        uuid: "8c0829a2-d0d5-36b3-5d05-65cce9a08358",
        noShowColumnSet: true, //不获取列配置信息
        mutiSelect: true, // 多选
        loading: true,
        getListApi: getTemplateList,
        body: {},
        listNo: true, // 序号
        labelWidth: "80",
        check: [], // 选中数据
        rowKey: "printTemplateId",
        tree: {
          title: "菜单",
          value: "menuId",
          label: "menuName",
          setData: (e) => {
            const menuIds = [];
            const setData = (obj) => {
              if (obj?.children?.length) {
                obj?.children.forEach((x) => {
                  setData(x);
                });
              } else {
                menuIds.push(obj.menuId);
              }
            };
            setData(e);
            return { menuIds };
          },
          dataKey: async ({ data } = {}) => {
            let treeData = [];
            const res = await getMenuIdList();
            let filterData = res.data;
            const filterTree = () => {
              const newData = data.filter((x) => filterData.includes(x.menuId));
              treeData = [...treeData, ...newData];
              if (newData?.length) {
                filterData = newData.map((x) => x.parentId);
                filterTree();
              }
            };
            filterTree();
            return this.handleTree(treeData, "menuId");
          },
          getListApi: getListMenuNotF,
        },
        search: [
          {
            type: "input",
            tip: "模板名称",
            model: "",
            filter: "query",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
        ],
        buttons: [
          {
            click: "add",
            type: "primary",
            label: "新增",
            icon: "el-icon-plus",
          },
          {
            click: "start",
            label: "启用",
            alertText: "确认要启用选中模板吗？",
            btnType: "dropdown",
            other: [
              { click: "close", label: "禁用", alertText: "确认要禁用选中模板吗？" },
            ],
          },
          {
            click: "defalut",
            label: "设为默认",
            type: "success",
            num: 1,
            icon: "el-icon-s-operation",
          },
          {
            click: "copy",
            label: "复制",
            type: "success",
            icon: "el-icon-document-copy",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            btnType: "dropdown",
            trigger: "click", // 要求
            alertText: "确认要删除选中打印模板吗？",
            other: [
              {
                label: "回收站",
                collectionDialog: {
                  restoreListApi: restorePrintTemplates,
                  dcollectionBody: {
                    delFlag: "1",
                  },
                },
              },
            ],
          },
          {
            click: "reset",
            label: "刷新",
            right: true,
          },
        ],
        columns: [
          {
            type: "link",
            prop: "printTemplateNo",
            label: "模板编号",
            minWidth: 155,
            click: "update",
          },
          {
            prop: "printTemplateName",
            label: "模板名称",
            minWidth: 155,
          },
          {
            prop: "menuName",
            label: "菜单名称",
            minWidth: 155,
          },
          {
            prop: "isDefault",
            label: "是否默认模板",
            minWidth: 155,
            formatter: (v) => (v ? "是" : "否"),
          },
          {
            prop: "isStop",
            label: "是否启用",
            minWidth: 90,
            formatter: (v) => (v ? "禁用" : "启用"),
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "update":
          this.$router.push({
            name: "printTemplateDetail",
            query: {
              printTemplateId: row.printTemplateId,
            },
          });
          break;
        case "add":
          {
            const options = {
              show: true,
              title: "新增打印模板",
              click: type,
              formData: {
                isStop: false,
              },
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "del":
          try {
            await deleteTemplate(this.options.check.map((x) => x.printTemplateId));
            this.$message.success("删除成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "start":
        case "close":
          try {
            await isStopTemplate({
              printTemplateIds: this.options.check.map((x) => x.printTemplateId),
              isStop: type === "close",
            });
            this.$message.success("设置成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "defalut":
          try {
            await setDefaultTemplate({
              menuId: this.options.check[0].menuId,
              printTemplateId: this.options.check[0].printTemplateId,
            });
            this.$message.success("设置成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "copy":
          try {
            await copyTemplate(this.options.check.map((x) => x.printTemplateId));
            this.$message.success("复制成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
