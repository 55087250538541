<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import Dialog from "@/components/Dialog";

import { getRemindList, delRemind, startOrStop } from "@/api/system/remind.js";
import { SYS_REMINDER_STATUS } from "@/utils/constant.js";

export default {
  name: "systemRemind",
  components: { TablePage, Dialog },
  data() {
    return {
      dialogOptions: {
        title: "",
        width: 620,
        show: false,
        type: "Remind",
        formData: null,
      },
      options: {
        uuid: "d759ff31-2f4e-662a-e7f0-cbaf868f8fa0",
        loading: true,
        mutiSelect: true,
        listNo: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: getRemindList,
        body: {}, // 请求内容
        title: "服务公告",
        check: [],
        rowKey: "sysRemindId",
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "start",
            label: "启用",
            btnType: "dropdown",
            other: [{ click: "stop", label: "停用" }],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "sysRemindTypeName",
            label: "提醒类型",
            minWidth: 180,
            type: "link",
            click: "update",
          },
          {
            prop: "sysRemindTitle",
            label: "提醒标题",
            minWidth: 180,
          },
          {
            prop: "sysRemindTimeName",
            label: "提醒时机",
            minWidth: 140,
          },
          {
            prop: "sysMenuPath",
            label: "关联菜单",
            minWidth: 130,
          },
          {
            prop: "isStart",
            label: "启用",
            formatter: (x) => (Number(x) === SYS_REMINDER_STATUS.start ? "启用" : "停用"),
            minWidth: 120,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "update":
          {
            const options = {
              show: true,
              title: "编辑系统提醒",
              click: type,
              formData: row,
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "add":
          {
            const options = {
              show: true,
              title: "新增系统提醒",
              click: type,
              formData: { sysRemindTimeName: "立即提醒" },
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "del":
          try {
            await delRemind(this.options.check.map((x) => x.sysRemindId));
            this.$message.success("删除成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "start":
          try {
            const arrs = this.options.check.map((x) => ({
              isStart: SYS_REMINDER_STATUS.start,
              sysRemindId: x.sysRemindId,
            }));
            await startOrStop(arrs);
            this.$message.success("启用成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "stop":
          try {
            const arrs = this.options.check.map((x) => ({
              isStart: SYS_REMINDER_STATUS.stop,
              sysRemindId: x.sysRemindId,
            }));
            await startOrStop(arrs);
            this.$message.success("停用成功！");
            this.getList();
          } catch (error) {}
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
